import { memo, useEffect, useRef, useState } from "react";
import api from "../../api/api";
import {
  Button,
  Card,
  Form,
  Input,
  Modal,
  Pagination,
  Popover,
  Select,
  Skeleton,
  message,
} from "antd";
import { FilterOutlined } from "@ant-design/icons";
import styles from "./Home.module.css";
import { PageHeader } from "@ant-design/pro-components";
import { useForm } from "antd/es/form/Form";
const Home = () => {
  const [products, setProducts] = useState(null);
  const productsRef = useRef([]);
  const [retailers, setRetailers] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [activeFilter, setActiveFilter] = useState("");
  const [filters, setFilters] = useState({});
  const [pagination, setPagination] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  useEffect(() => {
    api
      .get("/product_home")
      .then((res) => {
        setProducts(res.data);
        productsRef.current = structuredClone(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    api
      .get("/retailer_all")
      .then((res) => {
        setRetailers(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    const isModalSeen = localStorage.getItem("subscribe");
    if (!isModalSeen) {
      setOpenModal(true);
      localStorage.setItem("subscribe", true);
    }
  }, []);
  const sort = (field, ascending) => {
    const newProducts = structuredClone(products);
    newProducts.sort((a, b) => {
      if (ascending) {
        return a[field] - b[field];
      }
      return b[field] - a[field];
    });
    setProducts(newProducts);
  };
  const doFilters = () => {
    if (products) {
      const newProducts = [];
      structuredClone(productsRef.current).forEach((el) => {
        let shouldRender = true;
        Object.keys(filters).forEach((key) => {
          if (el[key] !== filters[key]) {
            shouldRender = false;
          }
        });
        if (shouldRender) {
          newProducts.push(el);
        }
      });
      setPagination(1);
      setProducts(newProducts);
    }
  };

  const addFilter = (filed, value) => {
    const newFilters = structuredClone(filters);
    newFilters[filed] = value;
    setFilters(newFilters);
  };
  const clearFilters = () => {
    setActiveFilter("");
    setProducts(structuredClone(productsRef.current));
    setFilters({});
  };
  useEffect(() => {
    doFilters();
  }, [filters]);
  let contents = [];
  if (Array.isArray(products)) {
    for (
      let i = pageSize * (pagination - 1);
      i < pageSize * pagination && i < products.length;
      i++
    ) {
      // console.log(i)
      const product = products[i];
      if (product) {
        let retailer = null;
        if (Array.isArray(retailers)) {
          for (let index = 0; index < retailers.length; index++) {
            const element = retailers[index];
            if (element.id == product.retailer) {
              retailer = element;
              break;
            }
          }
        }
        const element = (
          <Card
            style={{ width: 320 }}
            className={styles.ProductCard}
            key={product.id + product.name}
            cover={
              <div className={styles.CoverContainer}>
                <img
                  height={284}
                  src={product.image_url}
                  alt={`${product.brand} ${product.name}`}
                />
              </div>
            }
            actions={[
              <div>{`${product.ml} ml`}</div>,
              <div>
                {product.fragrance_class ? product.fragrance_class : "-"}
              </div>,
              retailer && (
                <a href={retailer.website} target="_blank">
                  {retailer.name}
                </a>
              ),
            ]}
          >
            <a
              className={styles.ProductCardContent}
              href={product.url}
              target="_blank"
            >
              <Card.Meta title={product.brand} description={product.name} />
              <Card.Meta
                title={
                  <div>
                    <span className={styles.OldPrice}>
                      {`${Math.floor(product.old_price * 100) / 100} ${
                        product.currency
                      }`}
                    </span>
                    <span>
                      {`${Math.floor(product.current_price * 100) / 100} ${
                        product.currency
                      }`}
                    </span>
                  </div>
                }
                description={`${product.price_difference} ${product.currency} 🔻`}
                className={styles.ProductPrice}
              />
            </a>
          </Card>
        );
        contents.push(element);
      }
    }
  }
  return (
    <div>
      <PageHeader
        title="Discounted fragrances"
        extra={[
          <Popover
            content={
              <>
                <div>
                  <Button
                    type={activeFilter === "most_discount" ? "primary" : "text"}
                    onClick={() => {
                      sort("price_difference" , true);
                      setActiveFilter("most_discount");
                    }}
                  >
                    Daily Fragrance Discounts
                  </Button>
                  <Button
                    type={activeFilter === "highest_price" ? "primary" : "text"}
                    onClick={() => {
                      sort("current_price");
                      setActiveFilter("highest_price");
                    }}
                  >
                    Highest Price
                  </Button>
                </div>

                <Select
                  onChange={(value) => {
                    addFilter("retailer", value);
                  }}
                  value={filters["retailer"]}
                  style={{ width: "100%", margin: "0.25rem 0" }}
                  loading={!Array.isArray(retailers)}
                  placeholder="retailer"
                >
                  {Array.isArray(retailers) &&
                    retailers.map((retailer) => {
                      return (
                        <Select.Option value={retailer.id}>
                          {retailer.name}
                        </Select.Option>
                      );
                    })}
                </Select>
                <Button
                  type="primary"
                  style={{ width: "100%", margin: "0.25rem 0" }}
                  onClick={clearFilters}
                >
                  Clear Filters
                </Button>
              </>
            }
          >
            <Button icon={<FilterOutlined />}>Filters</Button>
          </Popover>,
          <Button
            onClick={() => {
              setOpenModal(true);
            }}
            type="primary"
          >
            Subscribe
          </Button>,
        ]}
      />
      <div className={styles.Products}>
        {Array.isArray(products) ? (
          contents
        ) : (
          <>
            {[1, 2, 3, 4, 5, 6, 7, 8].map(() => {
              return (
                <Card
                  style={{ width: 320 }}
                  className={styles.ProductCard}
                  loading
                  cover={
                    <Skeleton.Image
                      active
                      style={{ width: "100%", height: 320 }}
                    />
                  }
                />
              );
            })}
          </>
        )}
      </div>
      {Array.isArray(products) && (
        <div className={styles.Pagination}>
          {" "}
          <Pagination
            className={styles.Pagination}
            current={pagination}
            pageSize={pageSize}
            pageSizeOptions={[10, 25, 50]}
            total={products.length}
            onChange={(current, pageSize) => {
              setPagination(current);
              setPageSize(pageSize);
              window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            }}
          />{" "}
        </div>
      )}
      <SubscribeModal open={openModal} setOpen={setOpenModal} />
    </div>
  );
};
const SubscribeModal = ({ open, setOpen }) => {
  const [countries, setCountries] = useState();
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    api
      .get("/country_all")
      .then((res) => {
        setCountries(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const onSubscribe = (values) => {
    setLoading(true);

    api
      .post("/subscriber_form", values)
      .then(() => {
        setLoading(false);
        setOpen(false);
        Modal.success({
          title: "Subscribed Successfully",
          content: "Thanks for your subscription!",
        });
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        message.error("Subscribe Failed");
      });
  };
  return (
    <Modal
      destroyOnClose
      confirmLoading={loading}
      title="Subscribe Form"
      open={open}
      onCancel={() => {
        setOpen(false);
        setLoading(false);
      }}
      okText="Subscribe"
      onOk={onSubscribe}
      okButtonProps={{ htmlType: "submit" }}
      footer={[]}
    >
      <Form
        form={form}
        disabled={loading}
        layout="vertical"
        onFinish={(values) => {
          onSubscribe(values);
        }}
      >
        <Form.Item
          rules={[{ required: true }]}
          label="Email"
          name="email"
          required
        >
          <Input />
        </Form.Item>
        <Form.Item
          rules={[{ required: true }]}
          label="Gender"
          name="gender"
          required
        >
          <Select>
            <Select.Option value="male">Male</Select.Option>
            <Select.Option value="female">Female</Select.Option>
            <Select.Option value="unisex">Unisex</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          rules={[{ required: true }]}
          required
          label="Country"
          name="country"
        >
          <Select loading={!Array.isArray(countries)}>
            {Array.isArray(countries) &&
              countries.map((el) => {
                return (
                  <Select.Option key={el.code} value={el.code}>
                    {el.name}
                  </Select.Option>
                );
              })}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Subscribe
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default memo(Home);
