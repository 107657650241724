const Logo = ({width , height}) => {
  return (
    <img
      src="/logo.png"
      alt=" Fragrance Hunt Logo "
      style={{width , height , padding : 16}}
    />
  );
};

export default Logo;
