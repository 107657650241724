import React from 'react';
import './App.css';
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import Home from './Containers/Home/Home';
import AppLayout from './Containers/Layout/Layout';
const router = createBrowserRouter(createRoutesFromElements(
  <Route path='' element={<AppLayout />}>
      <Route path='/' element={<Home />} />
  </Route>
))
function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
