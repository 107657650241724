import React from "react";
import { Button, Layout, Menu, theme } from "antd";
import { Outlet } from "react-router-dom";
import styles from './Layout.module.css';
import Logo from "../../Components/Logo/Logo";
const { Header, Content, Footer } = Layout;
const AppLayout = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  return (
    <Layout className="layout">
      <Header
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent : 'center',
          backgroundColor: "white",
        }}
      >
        {/* <Menu
          mode="horizontal"
          defaultSelectedKeys={["home"]}
          items={[{key : 'home' , label : 'home'}]}
        />
        <Button type="primary">Subscribe</Button> */}
        <Logo  height="100%" />
      </Header>
      <Content
        className={styles.Content}
      >
        <div
          
          style={{
            background: colorBgContainer,
            padding : '1rem'
          }}
        >
          <Outlet />
        </div>
      </Content>
      <Footer
        style={{
          textAlign: "center",
        }}
      >
        Fragrancehunt ©2023
      </Footer>
    </Layout>
  );
};
export default AppLayout;
